import './css/App.css';
import Header from "./components/Header";
import Body from "./components/Body";
import Download from "./components/Download";
import Footer from "./components/Footer";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function App() {
  return (
    <Container fluid className="App gradient-border-bottom-right">
        <Row fluid>
            <Col>
                <Header />
            </Col>
        </Row>
        <Row fluid>
            <Body />
        </Row>
        <Row>
            <Download
                text="Download Resume"
                path="./documents/resume.pdf"
                filename="Jake Williamson Resume.pdf"
            ></Download>
        </Row>
        <Row>
            <Footer />
        </Row>
    </Container>
  );
}

export default App;
